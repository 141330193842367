import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Spinner from './components/spinner/spinner';
import { authRoutes, sidebarRoutes } from './commons/types/routes';
import AuthenticationLayout from './layout/authentication/authentication_layout';
import { withFaroRouterInstrumentation } from '@grafana/faro-react';

const Home = lazy(() => import('./pages/home'));
const UploadProofOfAddress = lazy(() => import('./pages/signUp/components/add_proof_of_address'));
const OtpVerification = lazy(() => import('./pages/signUp/components/otp_verification'));
const SignUpLayout = lazy(() => import('./layout/signUp/signUpLayout'));
const SignUp = lazy(() => import('./pages/signUp/signUp'));
const Login = lazy(() => import('./pages/login'));
const LoginOtp = lazy(() => import('./pages/login/components/otp_verification'));
const UploadLogo = lazy(() => import('./pages/signUp/components/upload_logo'));
const TermsOfService = lazy(() => import('./pages/termsOfServices/index'));
const RequestResetPassword = lazy(() => import('./pages/forgotPassword/components/request_reset_password'));
const ForgotPasswordOtp = lazy(() => import('./pages/forgotPassword/components/verify_otp'));
const ResetPassword = lazy(() => import('./pages/forgotPassword/index'));

const Reviews = lazy(() => import('./pages/reviews/reviews'));
const Settings = lazy(() => import('./pages/settings/settings'));
const Promotion = lazy(() => import('./pages/promotion/promotion'));
const ReactivatePromo = lazy(() => import('./pages/promotion/components/reactivatePromo'));
const AddNewPromotion = lazy(() => import('./pages/promotion/components/addPromo/add_new'));
const Products = lazy(() => import('./pages/products/products'));
const UpdateProduct = lazy(() => import('./pages/products/components/updateProduct/update_product'));
const ProductDetails = lazy(() => import('./pages/products/components/productDetails/product_details'));
const AddProducts = lazy(() => import('./pages/products/components/addProducts.tsx/add_products'));
const Orders = lazy(() => import('./pages/orders/orders'));
const OrderDetails = lazy(() => import('./pages/orders/components/orderDetails/orderDetails'));
const Help = lazy(() => import('./pages/help/help'));
const Analytics = lazy(() => import('./pages/analytics/analytics'));
const AddNewAdvert = lazy(() => import('./pages/adverts/components/add_advert'));
const Adverts = lazy(() => import('./pages/adverts/adverts'));
const Accounting = lazy(() => import('./pages/accounting/accounting'));
const MainLayout = lazy(() => import('./layout/dashboard/main_layout'));
const PageNotFound = lazy(() => import('./pages/error'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <Home />
      </Suspense>
    ),
  },
  {
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <AuthenticationLayout />
      </Suspense>
    ),
    children: [
      {
        path: authRoutes.signIn,
        element: <Login />,
      },
      {
        path: authRoutes.signInOTP,
        element: <LoginOtp />,
      },
      {
        path: authRoutes.signUpOTP,
        element: <OtpVerification />,
      },
      {
        path: authRoutes.requestResetPassword,
        element: <RequestResetPassword />,
      },
      {
        path: authRoutes.requestResetPasswordOTP,
        element: <ForgotPasswordOtp />,
      },
      {
        path: authRoutes.resetPassword,
        element: <ResetPassword />,
      },
      {
        path: authRoutes.proofOfAddressUpload,
        element: <UploadProofOfAddress />,
      },
      {
        path: authRoutes.logoUpload,
        element: <UploadLogo />,
      },
    ],
  },
  {
    path: authRoutes.signUp,
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <SignUpLayout>
          <SignUp />
        </SignUpLayout>
      </Suspense>
    ),
  },
  {
    path: '',
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <TermsOfService />
      </Suspense>
    ),
  },
  {
    path: sidebarRoutes.dashboard,
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <MainLayout />
      </Suspense>
    ),
    children: [
      {
        path: sidebarRoutes.analytics,
        element: <Analytics />,
      },
      {
        path: sidebarRoutes.products,
        element: <Products />,
      },
      {
        path: sidebarRoutes.addProduct,
        element: <AddProducts />,
      },
      {
        path: sidebarRoutes.viewProduct,
        element: <ProductDetails />,
      },
      {
        path: sidebarRoutes.editProduct,
        element: <UpdateProduct />,
      },
      {
        path: sidebarRoutes.orders,
        element: <Orders />,
      },
      {
        path: sidebarRoutes.viewOrder,
        element: <OrderDetails />,
      },
      {
        path: sidebarRoutes.promo,
        element: <Promotion />,
      },
      {
        path: sidebarRoutes.addPromo,
        element: <AddNewPromotion />,
      },
      {
        path: sidebarRoutes.reactivatePromo,
        element: <ReactivatePromo />,
      },
      {
        path: sidebarRoutes.adverts,
        element: <Adverts />,
      },
      {
        path: sidebarRoutes.addAdvert,
        element: <AddNewAdvert />,
      },
      {
        path: sidebarRoutes.accounting,
        element: <Accounting />,
      },
      {
        path: sidebarRoutes.review,
        element: <Reviews />,
      },
      {
        path: sidebarRoutes.settings,
        element: <Settings />,
      },
      {
        path: sidebarRoutes.help,
        element: <Help />,
      },
    ],
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<Spinner message="Building page..." />}>
        <PageNotFound />
      </Suspense>
    ),
  },
]);

export const browserRouter = withFaroRouterInstrumentation(router);

import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { Api } from '../../../utils';
import {
  GetAllPayoutRequestResponsePayload,
  GetBalanceResponsePayload,
  IRequestPayoutPayload,
  IRequestResponsePayload,
} from '../accounting_types';

export class AccountingRepository {
  async getPayoutHistory(arg?: PaginationPayload): Promise<GetAllPayoutRequestResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetAllPayoutRequestResponsePayload, GetAllPayoutRequestResponsePayload>(
      'v1/vendor/withdrawal-history',
      {
        params,
      }
    );
    return response;
  }

  async getAllPendingPayoutRequest(arg?: PaginationPayload): Promise<GetAllPayoutRequestResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetAllPayoutRequestResponsePayload, GetAllPayoutRequestResponsePayload>(
      'v1/vendor/pending-withdrawal',
      {
        params,
      }
    );
    return response;
  }

  async getBalance(): Promise<GetBalanceResponsePayload> {
    const response = await Api.get<GetBalanceResponsePayload, GetBalanceResponsePayload>('/a/v1/vendor/balance');
    return response;
  }

  async requestPayout(payload: IRequestPayoutPayload): Promise<IRequestResponsePayload> {
    const response = await Api.post<IRequestResponsePayload, IRequestResponsePayload>('/a/v1/payment/payout', payload);
    return response;
  }
}

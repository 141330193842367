import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { FetchOrdersRequestPayload, GetOrderState } from '../order_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'GET_ORDERS';

const initialState: GetOrderState = {
  status: HttpStatus.IDLE,
  message: undefined,
  orders: [],
  paginationCursor: {
    after: '',
    before: '',
    hasNext: false,
    hasPrevious: false,
  },
};

export const getOrders = createAsyncThunk(`${NAMESPACE}/get`, async (payload: FetchOrdersRequestPayload) => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const response = await orderService.getOrders(payload);
  return response;
});

export const getOrdersSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.orders = payload.orders;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getOrders.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getOrdersSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetOrderDetailsState, ConfirmOrderPayload } from '../order_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'GET_ORDER_DETAILS';

const initialState: GetOrderDetailsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  order: undefined,
};

export const orderDetails = createAsyncThunk(`${NAMESPACE}/get`, async (payload: ConfirmOrderPayload) => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const response = await orderService.getOrderDetails(payload);
  return response;
});

export const orderDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetViewOrderStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(orderDetails.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(orderDetails.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.order = payload.order;
    });
    builder.addCase(orderDetails.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetViewOrderStatus } = orderDetailsSlice.actions;

export default orderDetailsSlice.reducer;

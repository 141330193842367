import { createSlice } from '@reduxjs/toolkit';
import { OrderTabs } from '../order_types';

const NAMESPACE = 'ORDER_TAB';

const initialState = {
  tab: OrderTabs.pending,
};

export const orderTabSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    switchTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { switchTab } = orderTabSlice.actions;

export default orderTabSlice.reducer;

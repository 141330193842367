import { HttpStatus } from '../../commons';
import { PaginationPayload } from '../../commons/types/pagination.type';
import { UserDetailsResponsePayload } from '../settings/setting_types';

export interface TotalPlacedOrderResponsePayload {
  totalPlacedOrders: number;
}

export interface TotalOrdersWithinDateRangeResponsePayload {
  noOfOrders: number;
}

export interface TotalPlacedOrderWithDatePayload {
  startDate: string;
  endDate: string;
}

export interface TotalPlacedOrderState {
  status: HttpStatus;
  message?: string;
  pendingOrders: TotalPlacedOrderResponsePayload;
  totalOrdersWithinDateRange: number;
}

export interface OrderProductResponsePayload {
  productId: string;
  quantity: number;
  price: number;
  amount: number;
  vendorId: string;
  image: string;
  name: string;
  addedAt: string;
  description?: string;
}

interface LocationT {
  type: string;
  coordinates: number[];
}

export interface OrdersResponsePayload {
  buyerId: string;
  vendorId: string;
  amount: {
    deliveryFee: number;
    unit: string;
    subtotal: number;
    total: number;
    vat: number;
    packaging: number;
    serviceCharge: number;
  };
  totalItemInCart: number;
  status: OrderStatus;
  location: LocationT;
  deliverTo: {
    state: string;
    city: string;
    address: string;
    addressId: string;
    location: LocationT;
  };
  paymentInfo: {
    paymentMethod: string;
    paymentStatus: string;
    paymentReference: string;
  };
  updatedAt: string;
  addressId: string;
  orderId: string;
  vendor: UserDetailsResponsePayload;
  cartType: string;
  commission?: {
    vendorCommission: number;
    commissionFromRider: number;
    commissionFromVendor: number;
    riderCommission: number;
    fleetOwnerCommission: number;
  };
  products: Array<OrderProductResponsePayload>;
  addedAt: string;
}

export interface GetOrdersResponsePayload {
  orders: Array<OrdersResponsePayload>;
  paginationCursor: paginationCursorResponsePayload;
}

export interface paginationCursorResponsePayload {
  before: string;
  after: string;
  hasNext: boolean;
  hasPrevious: boolean;
}

export interface GetOrderState extends GetOrdersResponsePayload {
  status: HttpStatus;
  message?: string;
}

export interface TotalOrderPerDayResponsePayload {
  sunday: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
}

export interface TotalOrderPerDayState {
  status: HttpStatus;
  message?: string;
  response: TotalOrderPerDayResponsePayload;
}

export interface TotalOrderPerDayPayload {
  startDate: string;
  endDate: string;
}

export interface OngoingOrderResponsePayload {
  orders: OrdersResponsePayload[];
  paginationCursor: paginationCursorResponsePayload;
}

export interface OngoingOrderState extends OngoingOrderResponsePayload {
  status: HttpStatus;
  message?: string;
}

export interface ConfirmOrderResponsePayload {
  confirmedOrder: true;
}

export interface ConfirmOrderPayload {
  orderId: string;
}

export interface ConfirmOrderState {
  status: HttpStatus;
  message?: string;
  response?: ConfirmOrderResponsePayload;
}

export interface DeclineOrderResponsePayload {
  declinedOrder: true;
}

export interface DeclineOrderPayload {
  orderId: string;
  reason: string;
}

export interface DeclineOrderState {
  status: HttpStatus;
  message?: string;
  response?: DeclineOrderResponsePayload;
}

export interface TotalNumberOfActiveOrderResponsePayload {
  totalOrderValue: number;
}

export interface NumberOfActiveOrderState {
  status: HttpStatus;
  message?: string;
  response?: TotalNumberOfActiveOrderResponsePayload;
}

export interface GetOrderDetailsResponsePayload extends Omit<OrdersResponsePayload, 'orderId'> {
  _id: string;
  vendorAddress: string;
}

export interface GetOrderItemDetailsResponsePayload {
  order: OrdersResponsePayload;
}

export interface GetOrderDetailsState {
  status: HttpStatus;
  message?: string;
  order?: OrdersResponsePayload;
}

export interface DeclineOrderFormInput {
  reason: string;
}

export interface GetTotalOrderResponsePayload {
  totalOrders: number;
}

export interface GetTotalOrderResponsePayloadInitialState {
  status: HttpStatus;
  message?: string;
  response: GetTotalOrderResponsePayload;
}

export enum OrderStatus {
  placed = 'placed', // pending orders
  ongoing = 'ongoing', // ongoing orders
  ready = 'ready', // ready orders
  confirmed = 'confirmed',
  dispatched = 'dispatched',
  completed = 'completed',
  delivered = 'delivered',
  'ready_for_pick_up' = 'ready_for_pick_up',
}

export enum OrderTabs {
  pending = 'pending',
  confirmed = 'confirmed',
  ongoing = 'ongoing',
  history = 'history',
}

export interface FetchOrdersRequestPayload extends PaginationPayload {
  status: OrderStatus | string;
}

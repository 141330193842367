import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { TbReload } from 'react-icons/tb';

interface ErrorFallBackUIProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallBackUI: React.FC<ErrorFallBackUIProps> = ({ error, resetErrorBoundary }) => {
  console.log(error.message, 'From Fallback UI');

  return (
    <Box
      role="alert"
      display="flex"
      flexDir="column"
      h="100vh"
      w={{ base: '100%', md: '70%', lg: '50%' }}
      mx="auto"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Box fontSize={{ base: '2.5rem', lg: '5rem' }} color="primaryLight" fontWeight="bold">
        Error!! 🌍
      </Box>
      <Box mt={{ base: '.5rem', lg: 'initial' }} fontSize="1.125rem">
        We apologize for the inconvenience, but our system has encountered an error. Our team is actively working to
        resolve this issue, and we appreciate your patience.
      </Box>
      <Button
        leftIcon={<TbReload />}
        bg="primaryLight"
        color="white"
        _hover={{ bg: 'primaryLight' }}
        _active={{ bg: 'primaryLight' }}
        borderRadius="md"
        mt="1rem"
        onClick={resetErrorBoundary}
      >
        Try again
      </Button>
    </Box>
  );
};

export default ErrorFallBackUI;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { NumberOfProductState } from '../product_types';
import { ProductsRepository } from '../repositories/products.repo';
import { ProductsService } from '../services/products.service';
import { GetInventoryCountRequestPayload } from '../../settings/setting_types';

const NAMESPACE = 'TOTAL_NUMBER_OF_PRODUCT';

const initialState: NumberOfProductState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: { inventoryCount: 0 },
};

export const totalNumberOfProduct = createAsyncThunk(
  `${NAMESPACE}/products`,
  async (payload: GetInventoryCountRequestPayload) => {
    const productsRepo = new ProductsRepository();
    const productsService = new ProductsService(productsRepo);
    const response = await productsService.getTotalNumberOfProduct(payload);
    return response.inventoryCount;
  }
);

const totalNumberOfProductSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(totalNumberOfProduct.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(totalNumberOfProduct.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response!.inventoryCount = payload;
    });
    builder.addCase(totalNumberOfProduct.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default totalNumberOfProductSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { IRequestPayoutPayload, RequestPayoutState } from '../accounting_types';
import { AccountingRepository } from '../repositories/accounting.repo';
import { AccountingService } from '../services/accounting.service';

const NAMESPACE = 'REQUEST_PAYOUT';

const initialState: RequestPayoutState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const requestPayout = createAsyncThunk(`${NAMESPACE}/post`, async (payload: IRequestPayoutPayload) => {
  const accountingRepo = new AccountingRepository();
  const accountingService = new AccountingService(accountingRepo);
  const response = accountingService.requestPayout(payload);
  return response;
});

const requestPayoutSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetRequestPayoutStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestPayout.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(requestPayout.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(requestPayout.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetRequestPayoutStatus } = requestPayoutSlice.actions;

export default requestPayoutSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ConfirmBankAccountPayload, ConfirmBankAccountState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'CONFIRM_BANK_ACCOUNT';

const initialState: ConfirmBankAccountState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: false,
};

export const confirmBankAccount = createAsyncThunk(`${NAMESPACE}/post`, async (payload: ConfirmBankAccountPayload) => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.confirmBankAccount(payload);
  return response;
});

const confirmBankAccountSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetConfirmBankAccountStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(confirmBankAccount.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(confirmBankAccount.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload.payload;
    });
    builder.addCase(confirmBankAccount.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetConfirmBankAccountStatus } = confirmBankAccountSlice.actions;

export default confirmBankAccountSlice.reducer;

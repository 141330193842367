import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ResolveBankAccountPayload, ResolveBankAccountState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'RESOLVE_BANK_ACCOUNT';

const initialState: ResolveBankAccountState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const resolveBankAccount = createAsyncThunk(`${NAMESPACE}/post`, async (payload: ResolveBankAccountPayload) => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.resolveBankAccount(payload);
  return response;
});

const resolveBankAccountSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetResolveBankAccountStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resolveBankAccount.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(resolveBankAccount.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(resolveBankAccount.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetResolveBankAccountStatus } = resolveBankAccountSlice.actions;

export default resolveBankAccountSlice.reducer;

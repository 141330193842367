import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { GetBankAccountsState } from '../setting_types';
import { SettingRepository } from '../repositories/setting.repo';
import { SettingService } from '../services/setting.service';

const NAMESPACE = 'GET_BANK_ACCOUNT';

const initialState: GetBankAccountsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  payload: [],
};

export const getBankAccount = createAsyncThunk(`${NAMESPACE}/get`, async () => {
  const settingRepo = new SettingRepository();
  const settingService = new SettingService(settingRepo);
  const response = await settingService.getBankAccounts();
  return response;
});

const getBankAccountSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBankAccount.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getBankAccount.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.payload = payload;
    });
    builder.addCase(getBankAccount.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getBankAccountSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { ConfirmOrderState, ConfirmOrderPayload } from '../order_types';
import { OrderRepository } from '../repositories/order.repo';
import { OrderService } from '../services/order.service';

const NAMESPACE = 'CONFIRM_ORDER';

const initialState: ConfirmOrderState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const confirmOrder = createAsyncThunk(`${NAMESPACE}/patch`, async (payload: ConfirmOrderPayload) => {
  const orderRepo = new OrderRepository();
  const orderService = new OrderService(orderRepo);
  const response = await orderService.confirmOrder(payload);
  return response;
});

export const confirmOrderSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(confirmOrder.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(confirmOrder.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(confirmOrder.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetStatus } = confirmOrderSlice.actions;

export default confirmOrderSlice.reducer;
